<template>
    <div>
        <el-card class="box-card">
          <el-button type="primary" size="mini" icon="el-icon-upload" @click="uploadlist">上传薪酬表</el-button>
          <el-link target="_blank" href="https://www.qchrs.com/api/v1/excel/template/download" :underline="false" style="margin-left:15px">
              <el-button type="primary" size="mini" icon="el-icon-download">下载薪酬模版</el-button>
          </el-link>
        </el-card>
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column align="center" prop="id" label="ID"></el-table-column>
            <el-table-column align="center" prop="company" label="公司名称" width="250" fixed="left"></el-table-column>
            <el-table-column align="center" prop="userName" label="姓名" fixed="left"></el-table-column>
            <el-table-column align="center" prop="cardId" label="身份证号码" width="160"></el-table-column>
            <el-table-column align="center" prop="jobAddress" label="工作地"></el-table-column>
            <el-table-column align="center" prop="entryDate" label="入职日期" width="120"></el-table-column>
            <el-table-column align="center" prop="leaveDate" label="离职日期" width="120"></el-table-column>
            <el-table-column align="center" prop="expectedAttendance" label="应出勤天数"></el-table-column>
            <el-table-column align="center" prop="compassionateLeave" label="事假天数"></el-table-column>
            <el-table-column align="center" prop="sickLeave" label="病假天数"></el-table-column>
            <el-table-column align="center" prop="workOvertime" label="加班天数"></el-table-column>
            <el-table-column align="center" prop="actualAttendance" label="实际出勤天数"></el-table-column>
            <el-table-column align="center" prop="probationPeriod" label="基本工资"></el-table-column>
            <el-table-column align="center" prop="regularSalary" label="岗位津贴"></el-table-column>
            <el-table-column align="center" prop="sickLeaveDeduction" label="其他补贴"></el-table-column>
            <el-table-column align="center" prop="personalLeaveDeduction" label="绩效奖金"></el-table-column>
            <el-table-column align="center" prop="overtimePay" label="其他1"></el-table-column>
            <el-table-column align="center" prop="subsidy" label="其他2"></el-table-column>
            <el-table-column align="center" prop="severanceCompensation" label="其他3"></el-table-column>
            <el-table-column align="center" prop="meritPay" label="加班工资"></el-table-column>
            <el-table-column align="center" prop="bonus" label="事病假扣款"></el-table-column>
            <el-table-column align="center" prop="otherDeductions" label="其他扣款"></el-table-column>
            <el-table-column align="center" prop="wagesPayable" label="应发工资"></el-table-column>
            <el-table-column align="center" prop="socialSecurity" label="社保个人扣款"></el-table-column>
            <el-table-column align="center" prop="providentFund" label="公积金个人扣款"></el-table-column>
            <el-table-column align="center" prop="supplementaryProvidentFund" label="补充公积金个人扣款"></el-table-column>
            <el-table-column align="center" prop="individualIncomeTax" label="个税扣款"></el-table-column>
            <el-table-column align="center" prop="reimbursement" label="税后加扣项"></el-table-column>
            <el-table-column align="center" prop="netSalary" label="实发工资"></el-table-column>
            <el-table-column align="center" prop="remarks" label="备注"></el-table-column>
            <el-table-column align="center" prop="tyearMonth" label="年月"></el-table-column>
            <el-table-column align="center" prop="cellPhone" label="手机号" width="110"></el-table-column>
            <el-table-column label="操作">
              <template v-slot="scope">
                <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row.id)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        <div class="pagination-wap">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="count">
          </el-pagination>
        </div>
        <el-dialog v-model="dialogTableVisible" title="上传薪酬表">
          <div class="uploadwap">
            <el-upload
              class="upload-demo"
              :headers="headerObj"
              action="https://www.qchrs.com/api/v1/excel/import"
              :on-success="handleAvatarSuccess"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              :multiple="false"
              :on-exceed="handleExceed"
              :file-list="fileList"
            >
              <el-button size="small" type="primary" icon="el-icon-upload">点击上传</el-button>
              <template #tip>
                <div class="el-upload__tip">
                  请上传xls/xlsx格式
                </div>
              </template>
            </el-upload>
          </div>
        </el-dialog>
    </div>
</template>

<script type="text/javascript">
import { ElMessage } from 'element-plus'
import { post, deletes } from '../../utils/request'
export default {
  name: 'SalaryList',
  data () {
    return {
      dialogTableVisible: false,
      gridData: [],
      headerObj: { salaryToken: window.sessionStorage.getItem('salaryToken') },
      fileList: [],
      tableData: [],
      count: 0, // 总数
      currentPage: 1,
      pageSize: 10
    }
  },
  created () {

  },
  mounted () {
    this.fetchSalaryList(this.currentPage, this.pageSize)
  },
  methods: {
    /**
     * 上传薪酬表
     */
    uploadlist () {
      this.dialogTableVisible = true
    },
    handleAvatarSuccess (response, file, fileList) { // 上传成功
      if (response.code === '0' && response.data === 'success') {
        ElMessage({ type: 'success', message: '上传成功' })
        this.dialogTableVisible = false
        this.fetchSalaryList(this.currentPage, this.pageSize)
      } else {
        ElMessage({ type: 'info', message: '上传失败' })
      }
    },
    handleRemove (file, fileList) { // 文件列表移除文件时的钩子
      console.log(fileList)
    },
    handlePreview (file) { // 点击文件列表中已上传的文件时的钩子
      console.log(file)
    },
    handleExceed (files, fileList) { // 文件超出个数限制时的钩子
      console.log(files)
      console.log(fileList)
      this.$message.warning('请选择一个文件')
    },
    beforeRemove (file, fileList) { // 删除文件之前的钩子，参数为上传的文件和文件列表。 若返回 false 或者返回 Promise 且被 reject，则终止删除。
      return this.$confirm(`是否确认删除${file.name} ?`)
    },
    /**
     * 获取薪酬列表
     */
    async fetchSalaryList (currentPage, pageSize) {
      const result = await post('/api/v1/salary/info/page', { currentPage: currentPage, pageSize: pageSize })
      if (result.code === '0') {
        this.tableData = result.data
        this.count = result.count
      }
    },
    /**
     * 分页
     */
    handleSizeChange (val) {
      this.pageSize = val
      this.fetchSalaryList(this.currentPage, this.pageSize)
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.fetchSalaryList(this.currentPage, this.pageSize)
    },
    /**
     * 删除
     */
    handleDelete (index, row) {
      var _this = this
      this.$confirm('此操作将删除此信息, 是否确定?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(index)
        console.log(row)
        _this.deleteSalary(row)
      }).catch(() => {
        this.$message({ type: 'info', message: '已取消删除' })
      })
    },
    async deleteSalary (row) {
      const result = await deletes(`/api/v1/salary/info/batch/${row}`, { ids: row })
      if (result.code === '0') {
        this.$message({ type: 'success', message: '删除成功!' })
        this.fetchSalaryList(this.currentPage, this.pageSize)
      } else {
        this.$message({ type: 'error', message: '删除失败!' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.box-card {
    margin-bottom: 20px;
    .el-form-item {
        margin-bottom: 0px;
    }
}
.pagination-wap {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}
.upload-demo {
  margin: 0 auto;
  text-align: center;
}
</style>
