<template>
    <div>
        <el-table
            :data="tableData"
            border
            style="width: 100%">
            <el-table-column align="center" prop="company" label="公司名称" width="250" fixed="left"></el-table-column>
            <!-- <el-table-column label="手机号" align="center" width="100">
                <template v-slot="scope">
                    <el-select v-model="scope.row.cellPhoneHas" size="mini" @change="cellPhoneHasChange(scope.row.id, scope.row.cellPhoneHas)" placeholder="请选择">
                        <el-option label="显示" value="true"></el-option><el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </template>
            </el-table-column> -->
            <el-table-column label="公司" align="center" width="100">
                <template v-slot="scope">
                    <el-select v-model="scope.row.companyHas" size="mini" @change="companyHasChange(scope.row.id, scope.row.companyHas)" placeholder="请选择">
                        <el-option label="显示" value="true"></el-option><el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="备注" align="center" width="100">
                <template v-slot="scope">
                    <el-select v-model="scope.row.remarksHas" size="mini" @change="remarksHasChange(scope.row.id, scope.row.remarksHas)" placeholder="请选择">
                        <el-option label="显示" value="true"></el-option><el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="实发工资" align="center" width="100">
                <template v-slot="scope">
                    <el-select v-model="scope.row.netSalaryHas" size="mini" @change="netSalaryHasChange(scope.row.id, scope.row.netSalaryHas)" placeholder="请选择">
                        <el-option label="显示" value="true"></el-option><el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="税后加扣项(报销)" align="center" width="100">
                <template v-slot="scope">
                    <el-select v-model="scope.row.reimbursementHas" size="mini" @change="reimbursementHasChange(scope.row.id, scope.row.reimbursementHas)" placeholder="请选择">
                        <el-option label="显示" value="true"></el-option><el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="个税扣款" align="center" width="100">
                <template v-slot="scope">
                    <el-select v-model="scope.row.individualIncomeTaxHas" size="mini" @change="individualIncomeTaxHasChange(scope.row.id, scope.row.individualIncomeTaxHas)" placeholder="请选择">
                        <el-option label="显示" value="true"></el-option><el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="补充公积金" align="center" width="100">
                <template v-slot="scope">
                    <el-select v-model="scope.row.supplementaryProvidentFundHas" size="mini" @change="supplementaryProvidentFundHasChange(scope.row.id, scope.row.supplementaryProvidentFundHas)" placeholder="请选择">
                        <el-option label="显示" value="true"></el-option><el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="公积金个人扣款" align="center" width="100">
                <template v-slot="scope">
                    <el-select v-model="scope.row.providentFundHas" size="mini" @change="providentFundHasChange(scope.row.id, scope.row.providentFundHas)" placeholder="请选择">
                        <el-option label="显示" value="true"></el-option><el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="社保个人扣款" align="center" width="100">
                <template v-slot="scope">
                    <el-select v-model="scope.row.socialSecurityHas" size="mini" @change="socialSecurityHasChange(scope.row.id, scope.row.socialSecurityHas)" placeholder="请选择">
                        <el-option label="显示" value="true"></el-option><el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="应发工资" align="center" width="100">
                <template v-slot="scope">
                    <el-select v-model="scope.row.wagesPayableHas" size="mini" @change="wagesPayableHasChange(scope.row.id, scope.row.wagesPayableHas)" placeholder="请选择">
                        <el-option label="显示" value="true"></el-option><el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="其他扣款" align="center" width="100">
                <template v-slot="scope">
                    <el-select v-model="scope.row.otherDeductionsHas" size="mini" @change="otherDeductionsHasChange(scope.row.id, scope.row.otherDeductionsHas)" placeholder="请选择">
                        <el-option label="显示" value="true"></el-option><el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="奖金" align="center" width="100">
                <template v-slot="scope">
                    <el-select v-model="scope.row.bonusHas" size="mini" @change="bonusHasChange(scope.row.id, scope.row.bonusHas)" placeholder="请选择">
                        <el-option label="显示" value="true"></el-option><el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="绩效工资" align="center" width="100">
                <template v-slot="scope">
                    <el-select v-model="scope.row.meritPayHas" size="mini" @change="meritPayHasChange(scope.row.id, scope.row.meritPayHas)" placeholder="请选择">
                        <el-option label="显示" value="true"></el-option><el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="离职补偿" align="center" width="100">
                <template v-slot="scope">
                    <el-select v-model="scope.row.severanceCompensationHas" size="mini" @change="severanceCompensationHasChange(scope.row.id, scope.row.severanceCompensationHas)" placeholder="请选择">
                        <el-option label="显示" value="true"></el-option><el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="补贴" align="center" width="100">
                <template v-slot="scope">
                    <el-select v-model="scope.row.subsidyHas" size="mini" @change="subsidyHasChange(scope.row.id, scope.row.subsidyHas)" placeholder="请选择">
                        <el-option label="显示" value="true"></el-option><el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="加班工资" align="center" width="100">
                <template v-slot="scope">
                    <el-select v-model="scope.row.overtimePayHas" size="mini" @change="overtimePayHasChange(scope.row.id, scope.row.overtimePayHas)" placeholder="请选择">
                        <el-option label="显示" value="true"></el-option><el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="事假扣款" align="center" width="100">
                <template v-slot="scope">
                    <el-select v-model="scope.row.personalLeaveDeductionHas" size="mini" @change="personalLeaveDeductionHasChange(scope.row.id, scope.row.personalLeaveDeductionHas)" placeholder="请选择">
                        <el-option label="显示" value="true"></el-option><el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </template>
            </el-table-column>

            <el-table-column label="病假扣款" align="center" width="100">
                <template v-slot="scope">
                    <el-select v-model="scope.row.sickLeaveDeductionHas" size="mini" @change="sickLeaveDeductionHasChange(scope.row.id, scope.row.sickLeaveDeductionHas)" placeholder="请选择">
                        <el-option label="显示" value="true"></el-option><el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="转正薪资" align="center" width="100">
                <template v-slot="scope">
                    <el-select v-model="scope.row.regularSalaryHas" size="mini" @change="regularSalaryHasChange(scope.row.id, scope.row.regularSalaryHas)" placeholder="请选择">
                        <el-option label="显示" value="true"></el-option><el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="试用期薪资" align="center" width="100">
                <template v-slot="scope">
                    <el-select v-model="scope.row.probationPeriodHas" size="mini" @change="probationPeriodHasChange(scope.row.id, scope.row.probationPeriodHas)" placeholder="请选择">
                        <el-option label="显示" value="true"></el-option><el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="实际出勤天数" align="center" width="100">
                <template v-slot="scope">
                    <el-select v-model="scope.row.actualAttendanceHas" size="mini" @change="actualAttendanceHasChange(scope.row.id, scope.row.actualAttendanceHas)" placeholder="请选择">
                        <el-option label="显示" value="true"></el-option><el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="加班天数" align="center" width="100">
                <template v-slot="scope">
                    <el-select v-model="scope.row.workOvertimeHas" size="mini" @change="workOvertimeHasChange(scope.row.id, scope.row.workOvertimeHas)" placeholder="请选择">
                        <el-option label="显示" value="true"></el-option><el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="病假天数" align="center" width="100">
                <template v-slot="scope">
                    <el-select v-model="scope.row.sickLeaveHas" size="mini" @change="sickLeaveHasChange(scope.row.id, scope.row.sickLeaveHas)" placeholder="请选择">
                        <el-option label="显示" value="true"></el-option><el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="事假天数" align="center" width="100">
                <template v-slot="scope">
                    <el-select v-model="scope.row.compassionateLeaveHas" size="mini" @change="compassionateLeaveHasChange(scope.row.id, scope.row.compassionateLeaveHas)" placeholder="请选择">
                        <el-option label="显示" value="true"></el-option><el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="应出勤天数" align="center" width="100">
                <template v-slot="scope">
                    <el-select v-model="scope.row.expectedAttendanceHas" size="mini" @change="expectedAttendanceHasChange(scope.row.id, scope.row.expectedAttendanceHas)" placeholder="请选择">
                        <el-option label="显示" value="true"></el-option><el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="离职日期" align="center" width="100">
                <template v-slot="scope">
                    <el-select v-model="scope.row.leaveDateHas" size="mini" @change="leaveDateHasChange(scope.row.id, scope.row.leaveDateHas)" placeholder="请选择">
                        <el-option label="显示" value="true"></el-option><el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="入职日期" align="center" width="100">
                <template v-slot="scope">
                    <el-select v-model="scope.row.entryDateHas" size="mini" @change="entryDateHasChange(scope.row.id, scope.row.entryDateHas)" placeholder="请选择">
                        <el-option label="显示" value="true"></el-option><el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="工作地" align="center" width="100">
                <template v-slot="scope">
                    <el-select v-model="scope.row.jobAddressHas" size="mini" @change="jobAddressHasChange(scope.row.id, scope.row.jobAddressHas)" placeholder="请选择">
                        <el-option label="显示" value="true"></el-option><el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="身份证号码" align="center" width="100">
                <template v-slot="scope">
                    <el-select v-model="scope.row.cardIdHas" size="mini" @change="cardIdHasChange(scope.row.id, scope.row.cardIdHas)" placeholder="请选择">
                        <el-option label="显示" value="true"></el-option><el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="姓名" align="center" width="100">
                <template v-slot="scope">
                    <el-select v-model="scope.row.userNameHas" size="mini" @change="userNameHasChange(scope.row.id, scope.row.userNameHas)" placeholder="请选择">
                        <el-option label="显示" value="true"></el-option><el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="年月" align="center" width="100">
                <template v-slot="scope">
                    <el-select v-model="scope.row.tyearMonthHas" size="mini" @change="tyearMonthHasChange(scope.row.id, scope.row.tyearMonthHas)" placeholder="请选择">
                        <el-option label="显示" value="true"></el-option><el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="操作">
              <template v-slot="scope">
                <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row.id)">删除</el-button>
              </template>
            </el-table-column>
        </el-table>
        <div class="pagination-wap">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="count">
          </el-pagination>
        </div>
    </div>
</template>

<script type="text/javascript">
import { ElMessage } from 'element-plus'
import { post, deletes } from '../../utils/request'
export default {
  name: 'CompanyList',
  data () {
    return {
      count: 0, // 总数
      currentPage: 1,
      pageSize: 10,
      tableData: []
    }
  },
  created () {

  },
  mounted () {
    this.fetchCompanyList(this.currentPage, this.pageSize)
  },
  methods: {
    async fetchCompanyList (currentPage, pageSize) {
      const result = await post('/api/v1/company/field/config/page', { currentPage: currentPage, pageSize: pageSize })
      if (result.code === '0') {
        this.tableData = result.data
        this.count = result.count
      }
    },
    /**
     * 分页
     */
    handleSizeChange (val) {
      this.pageSize = val
      this.fetchCompanyList(this.currentPage, this.pageSize)
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.fetchCompanyList(this.currentPage, this.pageSize)
    },
    async updateCompay (obj) { // 更新公司信息
      const result = await post('/api/v1/company/field/config', obj)
      console.log(result)
      if (result.code === '0') {
        ElMessage({ type: 'success', message: result.msg })
      } else {
        ElMessage({ type: 'info', message: result.msg })
      }
    },
    cellPhoneHasChange (id, iscellPhoneHas) { // 是否显示手机号
      this.updateCompay({ id: id, cellPhoneHas: iscellPhoneHas })
    },
    companyHasChange (id, iscompanyHas) { // 是否显示公司
      this.updateCompay({ id: id, companyHas: iscompanyHas })
    },
    remarksHasChange (id, isremarksHas) { // 是否显示备注
      this.updateCompay({ id: id, remarksHas: isremarksHas })
    },
    netSalaryHasChange (id, isnetSalaryHas) { // 是否显示实发工资
      this.updateCompay({ id: id, netSalaryHas: isnetSalaryHas })
    },
    reimbursementHasChange (id, isreimbursementHas) { // 是否显示税后加扣项(报销)
      this.updateCompay({ id: id, reimbursementHas: isreimbursementHas })
    },
    individualIncomeTaxHasChange (id, isindividualIncomeTaxHas) { // 是否显示个税扣款
      this.updateCompay({ id: id, individualIncomeTaxHas: isindividualIncomeTaxHas })
    },
    supplementaryProvidentFundHasChange (id, issupplementaryProvidentFundHas) { // 是否显示补充公积金
      this.updateCompay({ id: id, supplementaryProvidentFundHas: issupplementaryProvidentFundHas })
    },
    providentFundHasChange (id, isprovidentFundHas) { // 是否显示公积金个人扣款
      this.updateCompay({ id: id, providentFundHas: isprovidentFundHas })
    },
    socialSecurityHasChange (id, issocialSecurityHas) { // 是否显示社保个人扣款
      this.updateCompay({ id: id, socialSecurityHas: issocialSecurityHas })
    },
    wagesPayableHasChange (id, iswagesPayableHas) { // 是否显示应发工资
      this.updateCompay({ id: id, wagesPayableHas: iswagesPayableHas })
    },
    otherDeductionsHasChange (id, isotherDeductionsHas) { // 是否显示其他扣款
      this.updateCompay({ id: id, otherDeductionsHas: isotherDeductionsHas })
    },
    bonusHasChange (id, isbonusHas) { // 是否显示奖金
      this.updateCompay({ id: id, bonusHas: isbonusHas })
    },
    meritPayHasChange (id, ismeritPayHas) { // 是否显示绩效工资
      this.updateCompay({ id: id, meritPayHas: ismeritPayHas })
    },
    severanceCompensationHasChange (id, isseveranceCompensationHas) { // 是否显示离职补偿
      this.updateCompay({ id: id, severanceCompensationHas: isseveranceCompensationHas })
    },
    subsidyHasChange (id, issubsidyHas) { // 是否显示补贴
      this.updateCompay({ id: id, subsidyHas: issubsidyHas })
    },
    overtimePayHasChange (id, isovertimePayHas) { // 是否显示加班工资
      this.updateCompay({ id: id, overtimePayHas: isovertimePayHas })
    },
    personalLeaveDeductionHasChange (id, ispersonalLeaveDeductionHas) { // 是否显示事假扣款
      this.updateCompay({ id: id, personalLeaveDeductionHas: ispersonalLeaveDeductionHas })
    },
    sickLeaveDeductionHasChange (id, issickLeaveDeductionHas) { // 是否显示病假扣款
      this.updateCompay({ id: id, sickLeaveDeductionHas: issickLeaveDeductionHas })
    },
    regularSalaryHasChange (id, isregularSalaryHas) { // 是否显示转正薪资
      this.updateCompay({ id: id, regularSalaryHas: isregularSalaryHas })
    },
    probationPeriodHasChange (id, isprobationPeriodHas) { // 是否显示试用期薪资
      this.updateCompay({ id: id, probationPeriodHas: isprobationPeriodHas })
    },
    actualAttendanceHasChange (id, isactualAttendanceHas) { // 是否显示实际出勤天数
      this.updateCompay({ id: id, actualAttendanceHas: isactualAttendanceHas })
    },
    workOvertimeHasChange (id, isworkOvertimeHas) { // 是否显示加班天数
      this.updateCompay({ id: id, workOvertimeHas: isworkOvertimeHas })
    },
    sickLeaveHasChange (id, issickLeaveHas) { // 是否显示病假天数
      this.updateCompay({ id: id, sickLeaveHas: issickLeaveHas })
    },
    compassionateLeaveHasChange (id, iscompassionateLeaveHas) { // 是否显示事假天数
      this.updateCompay({ id: id, compassionateLeaveHas: iscompassionateLeaveHas })
    },
    expectedAttendanceHasChange (id, isexpectedAttendanceHas) { // 是否显示应出勤天数
      this.updateCompay({ id: id, expectedAttendanceHas: isexpectedAttendanceHas })
    },
    leaveDateHasChange (id, isleaveDateHas) { // 是否显示离职日期
      this.updateCompay({ id: id, leaveDateHas: isleaveDateHas })
    },
    entryDateHasChange (id, isentryDateHas) { // 是否显示入职日期
      this.updateCompay({ id: id, entryDateHas: isentryDateHas })
    },
    jobAddressHasChange (id, isjobAddressHas) { // 是否显示工作地
      this.updateCompay({ id: id, jobAddressHas: isjobAddressHas })
    },
    cardIdHasChange (id, iscardIdHas) { // 是否显示身份证号码
      this.updateCompay({ id: id, cardIdHas: iscardIdHas })
    },
    userNameHasChange (id, isuserNameHas) { // 是否显示姓名
      this.updateCompay({ id: id, userNameHas: isuserNameHas })
    },
    tyearMonthHasChange (id, istyearMonthHas) { // 是否显示年月
      this.updateCompay({ id: id, tyearMonthHas: istyearMonthHas })
    },
    /** 删除公司 */
    handleDelete (index, row) {
      var _this = this
      this.$confirm('此操作将删除此信息, 是否确定?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.deleteCompany(row)
      }).catch(() => {
        this.$message({ type: 'info', message: '已取消删除' })
      })
    },
    async deleteCompany (row) {
      const result = await deletes(`/api/v1/company/field/config/batch/${row}`, { ids: row })
      if (result.code === '0') {
        this.$message({ type: 'success', message: '删除成功!' })
        this.fetchCompanyList(this.currentPage, this.pageSize)
      } else {
        this.$message({ type: 'error', message: '删除失败!' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.box-card {
    margin-bottom: 20px;
    .el-form-item {
        margin-bottom: 0px;
    }
}
.pagination-wap {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}
.upload-demo {
  margin: 0 auto;
  text-align: center;
}
</style>
